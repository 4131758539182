@use '@angular/material' as mat;
@use 'typography' as typography;

$utbildning-primary: (
  50 : #e6ebec,
  100 : #c0ced1,
  200 : #97aeb2,
  300 : #6d8d93,
  400 : #4d747b,
  500 : #2e5c64,
  600 : #29545c,
  700 : #234a52,
  800 : #1d4148,
  900 : #123036,
  A100 : #74e4ff,
  A200 : #41daff,
  A400 : #0ed0ff,
  A700 : #00c5f4,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$sesame-accent: (
  50 : #fdf6e7,
  100 : #fbe9c3,
  200 : #f8da9b,
  300 : #f5cb73,
  400 : #f3bf55,
  500 : #f1b437,
  600 : #efad31,
  700 : #eda42a,
  800 : #eb9c23,
  900 : #e78c16,
  A100 : #ffffff,
  A200 : #fff2e4,
  A400 : #ffdbb1,
  A700 : #ffcf97,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$sesame-oss-primary: mat.m2-define-palette($utbildning-primary);
$sesame-oss-accent: mat.m2-define-palette($sesame-accent);

$utbildning-theme: mat.m2-define-light-theme((
  color: (
    primary: $sesame-oss-primary,
    accent: $sesame-oss-accent,
    warn: $sesame-oss-accent,
  ),
  density: 0,
  typography: typography.$config,
));

.oss-theme {
  @include mat.all-component-themes($utbildning-theme);
}

:root {
  --mdc-theme-error: var(--sesame-error-color);
  .oss-theme {
    --sesame-primary-color: #{mat.m2-get-color-from-palette($sesame-oss-primary)};
    --sesame-primary-light-color: #CCEDEA;
    --sesame-secondary-color: #{mat.m2-get-color-from-palette($sesame-oss-accent)};
    --sesame-primary-table-row-selected-color: #eef2f3;
  }
}